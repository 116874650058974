<template>
  <b-card no-body class="rounded-0 mb-1">
    <b-card-header
      header-tag="header"
      class="p-3 border-bottom-0 bg-light"
      role="tab"
      v-b-toggle="'accordion-' + index"
    >
      <i class="fad fa-sort-up draggable-sort-item" />
      <span class="pl-2 font-weight-light h6">{{ item.baslik }}</span>
    </b-card-header>
    <b-collapse
      :id="'accordion-' + index"
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-card-text>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form
              @submit.stop.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
              autocomplete="off"
            >
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    name="Sosyal Ağ"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      label="Sosyal Ağ"
                      :class="{ 'is-invalid': !!validationContext.errors[0] }"
                    >
                      <v-select
                        v-model="item.baslik"
                        :options="sosyal"
                        :clearable="true"
                        class="invoice-filter-select d-block select-size-lg pb-3"
                        :class="{ 'is-invalid': !!validationContext.errors[0] }"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <validation-provider
                    name="Url"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group label="Url">
                      <b-form-input
                        size="lg"
                        class="rounded-0 mb-3"
                        ref="url"
                        placeholder="https://example.com/firma"
                        v-model="item.url"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                  <validation-provider name="İcon">
                    <b-form-group label="İcon">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        ref="url"
                        v-model="item.icon"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <div class="d-flex">
                    <b-button
                      squared
                      block
                      type="submit"
                      size="lg"
                      variant="primary"
                      class="mr-2"
                    >
                      <i class="fad fa-save pr-2"></i>
                      <span class="align-middle">KAYDET</span>
                    </b-button>
                    <b-button
                      squared
                      size="lg"
                      variant="danger"
                      @click="handlerRemove(item.k_no)"
                    >
                      <i class="fad fa-trash"></i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import store from "@/store";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import { defineComponent, toRefs } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    index: {
      type: Number,
      requried: true,
    },
    item: {
      type: Object,
      requried: true,
    },
    sosyal: {
      type: Array,
      requried: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { item } = toRefs(props);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      store
        .dispatch("sosyalMedyaGuncelle", item.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success("Güncelleme Başarılı.", { position: "bottom-left" });
            context.emit("show", false);
          }
        })
        .catch((err) => {
          context.emit("show", false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0
                ? Object.keys(err.data.dup)[0] + " " + err.data.message
                : err.data.message,
              { position: "bottom-left" }
            );
          }
        });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: "Uyarı",
        text: "Firmaya Ait Tüm Kayit Silinecektir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch("sosyalMedyaSil", k_no).then((res) => {
            if (res.data.success === true) {
              toast.error("Silme başarılı.", { position: "bottom-left" });
              context.emit("show", false);
            }
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.draggable-sort-item {
  cursor: move;
}
</style>
